export const useStoreHeader = defineStore('StoreHeader', {
    state: () => ({
        menuOpen: false,
    }),
    actions: {
        toggleMenu () {
            this.menuOpen = !this.menuOpen;
        },
        closeMenu () {
            this.menuOpen = false;
        },
    },
    getters: {
        menuItems () {
            return [
                {route: '/', text: 'Home'},
                {route: '/courses', text: 'Courses'},
                {route: '/videos', text: 'Videos'},
                {route: '/live', text: 'Live'},
            ];
        }
    },
});
