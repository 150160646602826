<template>
    <div class="app-header-menu-hamburger" v-if="menuOpen">
        <div class="fixed inset-0 z-1000" @click="closeMenu()"></div>
        <div class="fixed inset-y-0 right-0 z-1000 w-full max-w-xs overflow-y-auto bg-white sm:max-w-md sm:ring-1 sm:ring-gray-900/10">
            <div class="flex items-center justify-between px-6 pb-1 pt-6">
                <div class="-m-1.5 p-1.5 text-gray-600">
                    {{ $config.public.siteName }}
                </div>
                <button type="button" class="-m-2.5 rounded-md p-2.5 text-zinc-900" @click="closeMenu()">
                    <span class="sr-only">Close menu</span>
                    <svg class="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                         aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div class="mt-6 flow-root px-6">
                <div class="-my-6 divide-y divide-gray-500/10" @click="closeMenu()">
                    <div class="space-y-2 py-6">
                        <NuxtLink
                            v-for="(item, index) in menuItems"
                            :key="'header-menu-mobile-item-' + index"
                            :class="menuItemClasses"
                            :to="item.route"
                        >
                            {{ item.text }}
                        </NuxtLink>
                    </div>
                    <div class="space-y-2 py-6">
                        <NuxtLink :class="menuItemClasses" to="/membership">Membership</NuxtLink>
                        <NuxtLink :class="menuItemClasses" to="/helpdesk">Helpdesk</NuxtLink>
                        <NuxtLink v-if="!isLoggedIn" :class="menuItemClasses" to="/login">Login</NuxtLink>
                        <NuxtLink v-if="!isLoggedIn" :class="menuItemClasses" to="/signup">Signup</NuxtLink>
                        <div v-if="isLoggedIn" class="relative">
                            <NuxtLink :class="menuItemClasses" to="/messages">Messages</NuxtLink>
                            <span
                                v-if="unreadCount"
                                class="absolute end-0 top-2 inline-flex h-6 w-6 items-center justify-center rounded-full bg-red-500 text-xs font-bold text-white"
                            >
                                {{ unreadCount }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'pinia';

export default {
    name: 'AppHeaderMenuHamburger',
    computed: {
        ...mapState(useStoreAuth, {
            isLoggedIn: 'isLoggedIn',
        }),
        ...mapState(useStoreHeader, {
            menuOpen: 'menuOpen',
        }),
        ...mapState(useStoreThreadsUnread, {
            unreadCount: 'unreadCount',
        }),
        ...mapGetters(useStoreHeader, {
            menuItems: 'menuItems'
        }),
        menuItemClasses () {
            const cls = {};
            cls['-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-600 hover:bg-gray-50 flex items-start'] = true;
            return cls;
        },
    },
    methods: {
        ...mapActions(useStoreHeader, {
            closeMenu: 'closeMenu',
        }),
    },
    watch: {
        $route () {
            this.closeMenu();
        },
    },
    beforeUnmount () {
        this.closeMenu();
    },
};
</script>
