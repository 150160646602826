<template>
    <nav class="app-header-me">
        <NuxtLink class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white" to="/me">
            <span class="sr-only">Open main menu</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-7 w-7">
                <path fill-rule="evenodd"
                      d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                      clip-rule="evenodd" />
            </svg>
        </NuxtLink>
    </nav>
</template>

<script>
import { mapActions } from 'pinia';

export default {
    name: 'AppHeaderMe',
    methods: {
        ...mapActions(useStoreHeader, {
            toggleMenu: 'toggleMenu',
        }),
    },
};
</script>
