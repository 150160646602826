<template>
    <NuxtLink :aria-label="$config.public.siteName" to="/">
        <span class="sr-only">{{ $config.public.siteName }}</span>
        <picture>
            <source :srcset="$config.public.images + $config.public.siteLogoAvif" type="image/avif" media/>
            <source :srcset="$config.public.images + $config.public.siteLogoWebp" type="image/webp" media/>
            <img :src="$config.public.images + $config.public.siteLogo" width="64" height="55" alt/>
        </picture>
    </NuxtLink>
</template>

<script>
export default {
    name: 'AppHeaderLogo',
};
</script>
