<template>
    <div class="app-body" :class="bodyClass">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AppBody',
    props: {
        bodyClass: {
            required: false,
            type: String,
            default: 'mb-auto',
        },
    },
};
</script>
