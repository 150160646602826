<template>
    <div class="app flex h-screen flex-col">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AppWrapper',
    data () {
        return {
            isMounted: false,
        };
    },
    mounted () {
        this.isMounted = true;
    }
};
</script>
