<template>
    <nav class="app-header-hamburger">
        <button type="button" class="relative -m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white" @click="toggleMenu()">
            <span class="sr-only">Open main menu</span>
            <svg class="h-9 w-9" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                 aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
            <span
                v-if="unreadCount"
                class="absolute end-0 top-2 inline-flex h-6 w-6 items-center justify-center rounded-full bg-red-500 text-xs font-bold text-white"
            >
                {{ unreadCount }}
            </span>
        </button>
    </nav>
</template>

<script>
import { mapActions } from 'pinia';

export default {
    name: 'AppHeaderHamburger',
    props: {
        unreadCount: {
            required: false,
            type: Number,
            default: 0,
        },
    },
    methods: {
        ...mapActions(useStoreHeader, {
            toggleMenu: 'toggleMenu',
        }),
    },
};
</script>
