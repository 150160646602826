<template>
    <header class="app-header" :class="headerClasses">
        <LayoutContained class="h-full">
            <div class="flex h-full flex-row justify-between text-2xl font-medium">
                <div class="flex justify-between py-2">
                    <AppHeaderLogo class="mr-8"></AppHeaderLogo>
                    <AppHeaderMenu class="hidden md:flex md:gap-x-4"></AppHeaderMenu>
                </div>
                <div class="grow"></div>
                <AppHeaderMe class="flex px-4 py-2" v-if="isLoggedIn"></AppHeaderMe>
                <AppHeaderLogin class="hidden py-2 md:flex md:gap-x-4" v-else></AppHeaderLogin>
                <AppHeaderMenuHamburger></AppHeaderMenuHamburger>
                <AppHeaderSearch></AppHeaderSearch>
                <AppHeaderHamburger :unreadCount="unreadCount" class="flex py-2"></AppHeaderHamburger>
            </div>
        </LayoutContained>
    </header>
</template>

<script>
import { mapState } from 'pinia';

export default {
    name: 'AppHeader',
    computed: {
        ...mapState(useStoreAuth, {
            isLoggedIn: 'isLoggedIn',
        }),
        ...mapState(useStoreThreadsUnread, {
            unreadCount: 'unreadCount',
        }),
        ...mapState(useStoreCourse, {
            course: 'course',
        }),
        headerClasses () {
            const cls = {};
            cls['min-h-[70px] h-[70px]'] = true;

            const routeName = useRoute()?.name;
            let transparentHeader = false;
            if (routeName === 'courses-courseSlug' && this.course?.id_course) transparentHeader = true;

            cls['bg-sky-600'] = !transparentHeader;
            cls['bg-transparent z-10 w-full absolute'] = transparentHeader;

            return cls;
        },
    },
};
</script>
