<template>
    <div class="app-header-search flex flex-row">
        <NuxtLink type="button" class="mr-2 inline-flex h-full items-center justify-center rounded-md px-4 text-white" to="/videos?focus=true">
            <span class="sr-only">Open search</span>
            <svg class="h-7 w-7 sm:h-8 sm:w-8" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
                      clip-rule="evenodd" />
            </svg>
        </NuxtLink>
    </div>
</template>

<script>
export default {
    name: 'AppHeaderSearch',
};
</script>
