<template>
    <nav class="app-header-menu">
        <NuxtLink
            v-for="(item, index) in menuItems"
            :key="'header-menu-item-' + index"
            :class="menuItemClasses"
            :to="item.route"
        >
            {{ item.text }}
        </NuxtLink>
    </nav>
</template>

<script>
import { mapGetters } from 'pinia';

export default {
    name: 'AppHeaderMenu',
    computed: {
        ...mapGetters(useStoreHeader, {
            menuItems: 'menuItems',
        }),
        menuItemClasses () {
            const cls = {};
            cls['text-base font-semibold leading-6 text-white pt-4 px-4'] = true;
            return cls;
        },
    },
};
</script>
