<template>
    <div class="app-header-login">
        <NuxtLink
            class="px-4 pt-4 text-base font-semibold leading-6 text-white"
            to="/login"
        >
            Login
        </NuxtLink>
    </div>
</template>

<script>
export default {
    name: 'AppHeaderLogin',
};
</script>
